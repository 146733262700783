import React, {
    FC,
    useState
} from 'react';

import {
    Button,
    SvgIcon,
} from '@mui/material';
import moment from 'moment';
import {ReactComponent as Save} from '../../assets/icons/save.svg';
import {
    generalSettingsType,
    operatingPointType
} from './ValvePilot';
import {useCSVDownloader} from 'react-papaparse';
import {useTranslation} from 'react-i18next';

interface ValvePilotCsvDownloadButtonProps {
    generalSettings: generalSettingsType,
    operatingPoints: operatingPointType[],
    columnsCount: number,
    disabledCalcButton: boolean,
    showIconButtons: boolean
}

const ValvePilotCsvDownloadButton: FC<ValvePilotCsvDownloadButtonProps> = ({ generalSettings, operatingPoints, columnsCount, disabledCalcButton, showIconButtons}) => {
    const { CSVDownloader } = useCSVDownloader();
    const {t} = useTranslation();
    const [csvFileName, setCsvFileName] = useState<string>();

    const formatCsv = () => {
        let csv: Array<string[] | unknown> = [];

        // generalSettings
        csv.push(['general settings']);
        for (const [key, value] of Object.entries(generalSettings!)) {
            if ( typeof value === 'object') {
                csv.push([key, value.UnitId, value.Value]);
            } else {
                csv.push([key, value]);
            }
        }

        // operatingPoints
        const filteredOperatingPoints = operatingPoints.slice(0, columnsCount);
        csv.push(['operating points']);
        csv.push(['AdditionalInformation', '', ...filteredOperatingPoints.map(op => op.AdditionalInformation ?? '')]);
        csv.push(['InletPressureP1', filteredOperatingPoints[0].InletPressureP1?.UnitId ?? '', ...filteredOperatingPoints.map(op => op.InletPressureP1.Value ?? '')]);
        csv.push(['OutletPressureP2', filteredOperatingPoints[0].OutletPressureP2?.UnitId ?? '', ...filteredOperatingPoints.map(op => op.OutletPressureP2.Value ?? '')]);
        csv.push(['TemperaturT1', filteredOperatingPoints[0].TemperaturT1?.UnitId ?? '', ...filteredOperatingPoints.map(op => op.TemperaturT1.Value ?? '')]);
        csv.push(['FlowRateQ', filteredOperatingPoints[0].FlowRateQ?.UnitId ?? '', ...filteredOperatingPoints.map(op => op.FlowRateQ.Value ?? '')]);

        return csv;
    }

    setInterval(() => {
        const date = moment().format('YYYY-MM-DD HH-mm-ss')
        setCsvFileName( 'valve-pilot ' + (generalSettings.tag ? generalSettings.tag + ' ' : '') + date);
    }, 1000);

    return !disabledCalcButton ? <CSVDownloader filename={csvFileName} config={{delimiter: ';'}} data={formatCsv} bom>
        <Button variant="contained" disabled={disabledCalcButton} startIcon={showIconButtons ? <SvgIcon component={Save} inheritViewBox /> : undefined}>{t('Buttons.SAVE_INPUT')}</Button>
    </CSVDownloader> : <Button variant="contained" startIcon={showIconButtons ? <SvgIcon component={Save} inheritViewBox /> : undefined} disabled>{t('Buttons.SAVE_INPUT')}</Button>;
};
export default ValvePilotCsvDownloadButton;

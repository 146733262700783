import React, {FC, ReactNode} from 'react';
import {
    Box,
    Grid,
    styled,
    SvgIcon,
    SxProps,
    Tooltip
} from '@mui/material';
import {ValvePilotInputProps} from './ValvePilotInput';
import ValvePilotTextBox from './ValvePilotTextBox';
import {ReactComponent as Info} from '../../assets/icons/info.svg';

export interface ValvePilotTableRowProps {
    title: string;
    info?: string;
    inputFields?: ValvePilotInputProps[];
    sx?: SxProps;
    children?: ReactNode;
}

const Row = styled(Grid)(({ theme }) => ({
    paddingBottom: '0.5rem',
    '&:last-child': {
        paddingBottom: 0
    }
}));

const ValvePilotTableRow: FC<ValvePilotTableRowProps> = ({title, info, inputFields, sx, children}) => {
    return <Row spacing={1} container sx={sx}>
        <Grid item xs={2}>
            <ValvePilotTextBox>
                <Box dangerouslySetInnerHTML={{__html: title}}/>
                {info && <Tooltip title={info} placement={'top'} arrow>
                  <SvgIcon component={Info} inheritViewBox/>
                </Tooltip>}
            </ValvePilotTextBox>
        </Grid>
        {children}
    </Row>
};

export default ValvePilotTableRow;

import {ThemeOptions, createTheme, Shadows} from "@mui/material/styles";

const valvePilot = "#a2162d";
const darkGrey = "#595f62";
const text = "#45494a";

const valvePilotThemeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: valvePilot,
        },
        secondary: {
            main: darkGrey,
        },
        text: {
            primary: text
        },
        error: {
            main: valvePilot,
        }
    },
    shadows: Array(25).fill('none') as Shadows
};

let theme = createTheme(valvePilotThemeOptions);

theme = createTheme(theme, {
    typography: {
        fontFamily: '"Open Sans", ' + theme.typography.fontFamily,
        fontSize: 10,
        allVariants: {
            color: text
        },
    },
    shape: {
        borderRadius: 0,
    },
    shadows: Array(25).fill('none') as Shadows,
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: '"Open Sans", ' + theme.typography.fontFamily,
                    color: text
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    marginBottom: 10,
                    '&.Mui-expanded': {
                        marginTop: 'unset',
                    },
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.main,
                    color:  'white',
                    minHeight: 36,
                    height: 36,
                    '&.Mui-expanded': {
                        minHeight: 'unset',
                        backgroundColor:  darkGrey,
                    },
                    '& .MuiAccordionSummary-content': {
                        '&.Mui-expanded': {
                            margin: '12px 0',
                        },
                        '.MuiTypography-root': {
                            color:  'white',
                        },
                    },

                    'svg': {
                        color: 'white',
                        fill: 'white'
                    }
                },
            }
        },
        MuiCollapse: {
            styleOverrides: {
                root: {
                    overflow: 'auto'
                }
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    backgroundColor:  theme.palette.grey[200],
                    border: 'none',
                    padding: '2rem',
                    minWidth: 1200
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontFamily: '"Open Sans", ' + theme.typography.fontFamily,
                    '& .MuiSvgIcon-root': {
                        backgroundColor: theme.palette.primary.main,
                        fill: theme.palette.primary.contrastText,
                        color: theme.palette.primary.contrastText,
                        '&.Mui-disabled': {
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.primary.contrastText,
                            opacity: 0.5
                        },
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.main
                        }
                    },
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    backgroundColor: 'white',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white'
                },
                notchedOutline: {
                    top: 0,
                    '& legend': {
                        display: 'none'
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: '"Open Sans", ' + theme.typography.fontFamily,

                    textTransform: 'none',
                    justifyContent: "flex-start",
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.main
                    }
                },
                startIcon: {
                    '& .MuiSvgIcon-root': {
                        fontSize: '30px'
                    }
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    padding: 0,
                    borderRadius: 0,
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.main
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '1em'
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                message: {
                    fontFamily:'"Open Sans", ' + theme.typography.fontFamily,
                }
            }
        }
    },
});

export const valvePilotTheme = theme;

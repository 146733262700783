import React, {
    ChangeEvent,
    FC,
    useRef,
} from 'react';

import {
    AlertProps,
    Button,
    styled,
    SvgIcon,
} from '@mui/material';


import {ReactComponent as Upload} from '../../assets/icons/upload.svg';

import {
    FluidType,
    generalSettingsType,
    operatingPointType
} from './ValvePilot';
import {usePapaParse} from 'react-papaparse';
import {useTranslation} from 'react-i18next';
import {ParseResult} from 'papaparse';

const Input = styled('input')({
    display: 'none'
});

interface ValvePilotCsvUploadButtonProps {
    setGeneralSettings: (generalSettings: generalSettingsType) => void;
    setOperatingPoints: (operatingPoints: operatingPointType[]) => void;
    setColumnsCount: (columnsCount: number) => void;
    setCalculationAlerts: (calculationAlerts: AlertProps[]) => void;
    setIsMediumSteam: (isMediumSteam: boolean) => void;
    setIsMediumFluid: (isMediumFlouid: boolean) => void;
    openDialog: (title: string, contentText: string, callback: () => void) => void;
    fluids?: FluidType[];
    clearAll: () => void;
    showIconButtons: boolean;
}

const ValvePilotCsvUploadButton: FC<ValvePilotCsvUploadButtonProps> = ({setGeneralSettings, setOperatingPoints, setColumnsCount, setCalculationAlerts, setIsMediumFluid, setIsMediumSteam, clearAll, fluids, openDialog, showIconButtons}) => {
    const {t} = useTranslation();
    const { readString } = usePapaParse();
    const refHiddenInput = useRef<HTMLInputElement>(null);

    const onOpenFile = () => {
        refHiddenInput.current!.value = '';
        refHiddenInput.current!.click();
    }

    const uploadCsv = (csvFile: File) => {
        if ( csvFile) {
            const fileReader = new FileReader();
            fileReader.onload = function (event) {
                const csvOutput = event!.target!.result;
                readString(csvOutput!.toString(), {
                    worker: true,
                    header: false,
                    complete: (csvParseResults: ParseResult<string[]>) => {
                        clearAll();

                        const generalSettingsIndex = csvParseResults.data.findIndex((value) => value.includes('general settings'));
                        const operatingPointsIndex = csvParseResults.data.findIndex((value) => value.includes('operating points'));

                        // General Settings
                        let generalSettingsTmp = csvParseResults.data.splice(generalSettingsIndex+1, operatingPointsIndex-1);

                        let generalSettingsTmp2: generalSettingsType = {};
                        generalSettingsTmp.forEach((row: string[]) => {
                            if (row.length > 2) {
                                generalSettingsTmp2 = {
                                    ...generalSettingsTmp2,
                                    [row[0]]: {
                                        UnitId: row[1],
                                        Value: Number(row[2])
                                    }
                                }
                            } else {
                                generalSettingsTmp2 = {
                                    ...generalSettingsTmp2,
                                    // [row[0]]: (row[1] === 'false' || 'true' ? Boolean(row[1]) : row[1])
                                    [row[0]]: (row[1] === 'false' ? false : (row[1] === 'true' ? true : row[1]))
                                }
                            }
                        });
                        setGeneralSettings(generalSettingsTmp2);

                        if (fluids) {
                            const fluid = fluids.find((f: FluidType) => f.ID === Number(generalSettingsTmp2.FluidId));
                            if (fluid) {
                                setIsMediumFluid(fluid.isMediumFluid);
                                setIsMediumSteam(fluid.isMediumSteam);
                            }
                        }

                        // Operating Points
                        let operatingPointsTmp = csvParseResults.data.splice(2, csvParseResults.data.length);
                        const operatingPointsCount = operatingPointsTmp[0].length-2; //-2 = Key & UnitId

                        if (operatingPointsCount <= 6) {
                            setColumnsCount(operatingPointsCount);

                            let operatingPointsTmp2: operatingPointType[] = [];
                            for(let i=2 ; i<operatingPointsTmp.length; i++) {
                                operatingPointsTmp2.push({
                                    AdditionalInformation: operatingPointsTmp?.[0]?.[i],
                                    InletPressureP1: {UnitId: operatingPointsTmp?.[1]?.[1], Value: Number(operatingPointsTmp?.[1]?.[i])},
                                    OutletPressureP2: {UnitId: operatingPointsTmp?.[2]?.[1], Value: Number(operatingPointsTmp?.[2]?.[i])},
                                    TemperaturT1: {UnitId: operatingPointsTmp?.[3]?.[1], Value: Number(operatingPointsTmp?.[3]?.[i])},
                                    FlowRateQ: {UnitId: operatingPointsTmp?.[4]?.[1], Value: Number(operatingPointsTmp?.[4]?.[i])},
                                } as operatingPointType);
                            }
                            setOperatingPoints(operatingPointsTmp2);
                        } else {
                            setCalculationAlerts([{
                                severity: 'error',
                                children: t('Alert.ERROR.InvalidCsv')
                            }]);
                            clearAll();
                            console.error(t('Alert.ERROR.InvalidCsv'), operatingPointsCount);
                        }
                    }
                });
            };
            fileReader.readAsText(csvFile);
        }
    }

    return <>
        <Input type="file" accept={".csv"} onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files?.[0] ? uploadCsv(e.target.files[0]) : undefined} ref={refHiddenInput}/>
        <Button variant="contained" onClick={() => openDialog(t('Dialog.UPLOAD_FILE.TITLE'), t('Dialog.UPLOAD_FILE.CONTENT_TEXT'), onOpenFile)} startIcon={showIconButtons ? <SvgIcon component={Upload} inheritViewBox /> : undefined}>{t('Buttons.LOAD_INPUT')}</Button>
    </>;
};
export default ValvePilotCsvUploadButton;

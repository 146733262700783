import React, {createContext, Suspense, useEffect, useState} from 'react';
import ValvePilot from './components/ValvePilot/ValvePilot';
import {ThemeProvider} from '@mui/material';
import {valvePilotTheme} from './theme/valvePilotTheme';

type language = {
    [key: string]: string
}

interface ConfigContextProps {
    proxyUrl: string;
    apiVersion: string;
    configuratorUrl: string;
    fractionDigits: number;
    languages: language[];
}

export const ConfigContext = createContext<ConfigContextProps|undefined>({
    proxyUrl: '',
    apiVersion: '',
    configuratorUrl: '',
    fractionDigits: 3,
    languages: []
})

function App () {
    const [config, setConfig] = useState<ConfigContextProps>();

    useEffect(() => {
        fetch("./config.json").then((res) => {
            return res.json()
        }).then((config) => {
            if(config) {
                setConfig(config);
            }
        });

        const packageJson = require('../package.json');
        console.log('Version:', packageJson.version);
    }, []);

    return (
        <Suspense>
            <ThemeProvider theme={valvePilotTheme}>
                <ConfigContext.Provider value={config}>
                    <ValvePilot sx={{marginY: 3, maxWidth: 1569}} showIconButtons/>
                    {/*<ValvePilotDynamic data={tableData} sx={{marginTop: 3}}/>*/}
                </ConfigContext.Provider>
            </ThemeProvider>
        </Suspense>
    );
}

export default App;

import i18n, {ResourceKey} from "i18next";
import {initReactI18next} from "react-i18next";
import backend, {HttpBackendOptions} from 'i18next-http-backend';
import axios from 'axios';

const loadResources=async(locale:string)=> {
        const apiVersion = 'v1';
        return await axios.get(process.env.REACT_APP_BASE_URL + `/Translation/api/` + apiVersion + `/Translation/` + locale)
            .then(function(res) { return res.data })
            .catch(function(error) { console.log(error) });
}

const backendOptions = {
    loadPath: '{{lng}}|{{ns}}',
    request: (options: HttpBackendOptions, url: string, payload: {} | string, callback: (error: any, response: { status: number; data: ResourceKey; }) => void) => {
        try {
            const [lng] = url.split('|');
            loadResources(lng).then((response) => {
                callback(null, {
                    data: response, status: 200,
                });
            });
        } catch (e) {
            console.error(e);
            /*callback(null, {
               data: undefined, status: 500,
            });*/
        }
    },
};

i18n
    .use(initReactI18next)
    .use(backend)
    .init({
        // debug: true,
        backend: backendOptions, ns: ["valvePilot"], defaultNS: "valvePilot", lng: "EN", keySeparator: ".", interpolation: {
            escapeValue: false
        },
    })
    .then(r => console.log('i18n init'))
    .catch(r => console.error('i18n error'));

export default i18n;

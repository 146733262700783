import React, {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import {NativeSelect, TextField, Typography, OutlinedInput, SvgIcon, debounce} from '@mui/material';
import {ReactComponent as ArrowDown} from '../../assets/icons/arrowDown.svg';

export interface ValvePilotInputOption {
    [key: string]: string | undefined;
}
export interface ValvePilotInputProps {
    type: string | 'select' | 'text' | 'number' | 'placeholder' | 'btn' | 'icon-btn' | 'info';
    defaultValue?: string | number |boolean;
    disabled?: boolean;
    grid?: number;
    icon?: string;
    id?: string;
    info?: string;
    options?: ValvePilotInputOption[];
    orderByValue?: boolean;
    readonly?: boolean;
    required?: boolean;
    tabIndex?: number;
    value?: string | number| boolean | null;
    onChange?: (value?: string | number | boolean) => void;
    min?: number;
    max?: number;
    step?: string;
    biggerNull?: boolean;
}
const ValvePilotInput: FC<ValvePilotInputProps> = (props) => {
    const {
        disabled,
        id,
        orderByValue,
        readonly,
        required,
        tabIndex,
        type,
        onChange,
        min,
        max,
        biggerNull,
        step
    } = props;
    const [value, setValue] = useState(props.value);

    const onChangeDebounced = useCallback(
        debounce(onChange!, 1000)
    , []);

    useEffect(() => {
        if (value !== props.value) {
            setValue(props.value);
        }
    }, [props.value]);

    const onKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            // setValue(e.target.value);
            changeValue(e)
        }
    }

    const changeValue = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> ) => {
        let value: string | number | boolean | undefined;
        switch (e.target?.value) {
            case 'true':
                value = true;
                break;
            case 'false':
                value = false;
                break;
            default:
                value = (type === 'number' ? (e.target?.value ? Number(e.target?.value) : undefined) : e.target?.value);
                break;
        }

        if (value !== props.value) {
            setValue(value);
            if (onChange) {
                onChange(value);
                // ToDo: Failt bei onChange Inlet
                // onChangeDebounced(value);
            }
        }
    }

    let selectOptions;

    if (orderByValue) {
        selectOptions = props.options?.sort((a, b) => {
            let valueA = Object.values(a)![0]?.toLowerCase();
            let valueB = Object.values(b)![0]?.toLowerCase();
            return valueA! > valueB! ? 1 : -1;
        })
    } else {
        selectOptions = props.options
    }

    switch (type) {
        case 'info':
            return <Typography color={'primary'} sx={{display: 'flex', alignItems: 'center', height: '100%'}}>{value}</Typography>
        case 'placeholder':
            return <></>;
        case 'select':
            return <NativeSelect variant={'outlined'} size={'small'} fullWidth
                                 disabled={disabled}
                                 readOnly={readonly}
                                 required={required}
                                 value={value}
                                 onChange={(e) => changeValue(e)}
                                 inputProps={{ tabIndex: tabIndex }}
                                 input={<OutlinedInput id={id} />}
                                 IconComponent={({ className }) => {
                                     return <SvgIcon component={ArrowDown} className={className} inheritViewBox/>
                                 }}>
                {!required && <option value={''}></option>}
                {selectOptions?.map((o: ValvePilotInputOption) => {
                    return <option key={Object.keys(o).toString()} value={Object.keys(o)}>{Object.values(o)}</option>
                })}
            </NativeSelect>;
        default:
        case 'text':
        case 'number':
            return <TextField type={type}
                              variant={'outlined'}
                              size={'small'}
                              fullWidth
                              disabled={disabled}
                              required={required}
                              InputProps={{ inputProps: { tabIndex: tabIndex, min: biggerNull ? (min && min > 0 ? min : (step ?? '0.000001')) : min, max: max, step: step ?? 'any' } }}
                              value={value ?? (type === 'text' ? '' : 0)}
                              // onChange={(e) => changeValue(e)}
                              onChange={(e) => setValue(e.target.value)}
                              onBlur={(e) => changeValue(e)}
                              onKeyDown={onKeyDown}
            />;

    }
};

export default ValvePilotInput;

import React, {
    FC,
    Fragment,
    useContext
} from 'react';
import {useTranslation} from 'react-i18next';

import {Grid} from '@mui/material';

import ValvePilotProductCard, {ValvePilotProduct} from './ValvePilotProductCard';
import ValvePilotAccordion from './ValvePilotAccordion';
import {ConfigContext} from '../../App';

interface ValvePilotProductListProps {
    productList: ValvePilotProduct[];
}
const ValvePilotProductList: FC<ValvePilotProductListProps> = ({productList}) => {
    const config = useContext(ConfigContext);
    const {t} = useTranslation();

    return <Fragment>
        <ValvePilotAccordion title={t('Global.TITLE_DEVICES')} defaultExpanded>
            <Grid spacing={1} container sx={{marginBottom: -3}}>
                {productList.map(product => <Grid key={product.ProductId} item xs={6}><ValvePilotProductCard product={product} configuratorUrl={config?.configuratorUrl}/></Grid>)}
            </Grid>
        </ValvePilotAccordion>
        {/*<ValvePilotProductListLabel/>*/}
    </Fragment>
};
export default ValvePilotProductList;

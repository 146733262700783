import React, {
    FC,
    FormEvent,
    Fragment,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react';

import {useTranslation} from 'react-i18next';
import axios from 'axios';

import {
    Alert,
    AlertProps,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    NativeSelect,
    OutlinedInput,
    styled,
    SvgIcon,
    SxProps,
} from '@mui/material';

import {ConfigContext} from '../../App';

import {ValvePilotInputOption} from './ValvePilotInput';
import {ValvePilotProduct} from './ValvePilotProductCard';

import ValvePilotSizingInput from './ValvePilotSizingInput';
import ValvePilotFluidProperties from './ValvePilotFluidProperties';
import ValvePilotOurSolutions from './ValvePilotOurSolutions';
import ValvePilotProductList from './ValvePilotProductList';

import {ReactComponent as Cancel} from '../../assets/icons/cancel.svg';
import {ReactComponent as Check} from '../../assets/icons/check.svg';
import {ReactComponent as Calculate} from '../../assets/icons/calculate.svg';
import {ReactComponent as Clear} from '../../assets/icons/clear.svg';
import {ReactComponent as Translate} from '../../assets/icons/translate.svg';
import ValvePilotCsvDownloadButton from './ValvePilotCsvDownloadButton';
import ValvePilotCsvUploadButton from './ValvePilotCsvUploadButton';

interface ValvePilotProps {
    sx?: SxProps;
    showIconButtons: boolean;
}

export interface FluidType {
    ID: number;
    Name: string;
    isMediumFluid: boolean;
    isMediumSteam: boolean;
}

interface IResponse {
    data: IResponseData;
}

interface IResponseData {
    CalcResultReponse: CalcResultReponse;
    ProduktVorschlaege: ValvePilotProduct[];
    ResultsProBetriebspunkt?: ResultType[];
}


interface CalcResultReponse {
    HinweiseGlobal: MessageType[];
    ResultsProBetriebspunkt: ResultType[];
    SuggestedPipeSizeIn: UnitIdValueType;
    SuggestedPipeSizeOut: UnitIdValueType;
}

export interface UnitIdValueType {
    Value?: number;
    tmpValue?: number ;
    UnitId?: string;
    tmpUnitId?: string;
}
export interface generalSettingsType {
    tag?: string;
    inlet?: string;
    outlet?: string;
    ValveType?: string;
    FluidName?: string;
    FluidId?: number;
    useVelocityLimit?: boolean;
    InletVelocityLimit?: UnitIdValueType;
    OutletVelocityLimit?: UnitIdValueType;
    InletPipeLimit?: UnitIdValueType;
    OutletPipeLimit?: UnitIdValueType;
}

export interface operatingPointType {
    InletPressureP1: UnitIdValueType;
    OutletPressureP2: UnitIdValueType;
    TemperaturT1: UnitIdValueType;
    FlowRateQ: UnitIdValueType;
    Flow?: string;
    AdditionalInformation?: string;
}

export interface fluidPropertiesType {
    Density?: UnitIdValueType;
    Viscosity?: UnitIdValueType;
    MolecularWeight?: UnitIdValueType;
    Pv: UnitIdValueType;
    Pc: UnitIdValueType;
}

export interface BerechneteEigenschaftenType extends fluidPropertiesType{
    CompressibilityFactorZ: number;
    SpecificHeatRatio: number;
}
export interface ResultType {
    Cv: UnitIdValueType;
    Cvs: UnitIdValueType;
    FlangeDiameter: UnitIdValueType;
    Kv: UnitIdValueType;
    Kvs: UnitIdValueType;
    PipeDownstreamVelocity: UnitIdValueType;
    PipeUpstreamVelocity: UnitIdValueType;
    PredictedNoiseLevel: UnitIdValueType;
    ValveInletVelocity: UnitIdValueType;
    ValveOutletVelocity: UnitIdValueType;
    ReductionRatio: number;
    Flow: string;

    BerechneteEigenschaftenMediumFluid: BerechneteEigenschaftenType;
    BerechneteEigenschaftenMediumGas: BerechneteEigenschaftenType;

    Hinweise: MessageType[];
    isMediumFluid: boolean;
    isMediumSteam: boolean;
    isMediumFluidTmp?: boolean;
}

interface MessageType extends AlertProps {
    MessageText: string;
    MessageType: string;
    Severity: string;
}

interface DialogType {
    open: boolean;
    title: string;
    contentText: string;
    callback: () => void;
}

type UnitType = 'Pressure' | 'Velocity' | 'Temperature' | 'FluidVolumeFlow' | 'GasVolumeFlow' | 'MassFlow' | 'Density' | 'Viscosity' | 'SpecificHeatRatio' | 'MolecularWeight' | 'Compressibility' | 'Diameter' | 'NoiseLevel' | 'PipeDimensions' | 'KinematicViscosity';
export type supportedUnitIdsType = {
    [key in UnitType]?: ValvePilotInputOption[];
};

const ButtonGroup = styled(Box)({
    display: 'flex',
    width: 'inherit',
    justifyContent: 'end',
    gap: '1rem',
    marginBottom: '1rem'
});

const ValvePilot: FC<ValvePilotProps> = ({ sx,showIconButtons}) => {
    const config = useContext(ConfigContext);

    const urlParams = new URLSearchParams(window.location.search);
    const [language, setLanguage] = useState(urlParams.get("language")?.toLowerCase());
    const {t, i18n} = useTranslation();

    const [fluids, setFluids] = useState<FluidType[]>();
    const [supportedUnitIds, setSupportedUnitIds] = useState<supportedUnitIdsType>({});

    const [defaultGeneralSettings, setDefaultGeneralSettings] = useState<generalSettingsType>();
    const [defaultOperatingPoint, setDefaultOperatingPoint] = useState<operatingPointType>();

    const [generalSettings, setGeneralSettings] = useState<generalSettingsType>();
    const [operatingPoints, setOperatingPoints] = useState<operatingPointType[]>([]);
    const [columnsCount, setColumnsCount] = useState(1);

    const [productList, setProductList] = useState<ValvePilotProduct[]>([]);
    const [results, setResults] = useState<ResultType[]>([]);

    const [hasChanges, setHasChanges] = useState(false);
    const [isCalculated, setIsCalculated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isMediumFluid, setIsMediumFluid] = useState(false);
    const [isMediumSteam, setIsMediumSteam] = useState(false);

    const [alerts, setAlerts] = useState<AlertProps[]>([]);
    const [calculationAlerts, setCalculationAlerts] = useState<AlertProps[]>([]);
    const [dialog, setDialog] = useState<DialogType>();

    const refForm = useRef<HTMLFormElement>(null);

    useEffect(() => {
        async function fetchData(url: string) {
            const res =  await fetch(url);
            return res.json();
        }
        setIsLoading(true);
        fetchData("./defaultOperatingPoint.json").then((defaultOperatingPoint) => {
            if(defaultOperatingPoint) {
                setDefaultOperatingPoint(defaultOperatingPoint);
                setOperatingPoints([
                    defaultOperatingPoint,
                    defaultOperatingPoint,
                    defaultOperatingPoint,
                    defaultOperatingPoint,
                    defaultOperatingPoint,
                    defaultOperatingPoint
                ] as operatingPointType[]);
            }
        });

        fetchData("./defaultGeneralSettings.json").then((defaultGeneralSettings) => {
            if(defaultGeneralSettings) {
                setDefaultGeneralSettings(defaultGeneralSettings);
                setGeneralSettings(defaultGeneralSettings);
            }
        });
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (operatingPoints.length < columnsCount+1) {
            setOperatingPoints((prevState) => {
                const operatingPointsTmp = prevState;
                operatingPointsTmp.push(defaultOperatingPoint as operatingPointType)
                return operatingPointsTmp;
            })
        }
        if (isCalculated && hasChanges || results?.length > columnsCount) {
            refForm?.current?.requestSubmit();
            setHasChanges(false);
        }

    }, [columnsCount]);

    useEffect(() => {
        setIsLoading(true);
        async function getData() {
            await getFluids();
            await getSupportedUnitIds('Density');
            await getSupportedUnitIds('FluidVolumeFlow');
            await getSupportedUnitIds('GasVolumeFlow');
            await getSupportedUnitIds('KinematicViscosity');
            await getSupportedUnitIds('MassFlow');
            await getSupportedUnitIds('MolecularWeight');
            await getSupportedUnitIds('PipeDimensions');
            await getSupportedUnitIds('Pressure');
            await getSupportedUnitIds('Temperature');
            await getSupportedUnitIds('Velocity');
            await getSupportedUnitIds('Viscosity');
        }

        if (config?.apiVersion) {
            getData().then(() => {
                setIsLoading(false);
            })
        }
    }, [config?.apiVersion]);

    useEffect(() => {
        if (language)  {
            i18n.changeLanguage(language).then(() => {
                // console.log('changeLanguage to', language);
                document.documentElement.lang = language;
            });
        }
    }, [language]);

    useEffect(() => {
        // console.log('generalSettings', generalSettings, hasChanges);
        if (isCalculated && hasChanges) {
            refForm?.current?.requestSubmit();
            setHasChanges(false);
        }
    }, [generalSettings]);

    useEffect(() => {
        // console.log('operatingPoints', operatingPoints, hasChanges);
        if (isCalculated && hasChanges) {
            refForm?.current?.requestSubmit();
            setHasChanges(false);
        }
    }, [operatingPoints]);

    const getFluids = async () => {
        await axios.get(config?.proxyUrl + `/ValveCalc/api/` + config?.apiVersion + `/Calculation/Fluids`).then((res) => {
            const fluids = res.data;
            setFluids(fluids);
        });
    }

    const getSupportedUnitIds = async(unitType: UnitType) => {
        await axios.get(config?.proxyUrl + `/UnitConversions/api/` + config?.apiVersion + `/SupportedUnitIDs/` + unitType).then((res) => {
            const supportedUnitIds = res.data.map((supportedUnitId:string) => {return {[supportedUnitId]: t('Unit.' + unitType + '.' + supportedUnitId)}});
            setSupportedUnitIds(prevState => {
                return {
                    ...prevState,
                    [unitType]: supportedUnitIds
                }
            })
        })
    }

    if (!generalSettings) {
        return <Fragment/>;
    }

    const fixOperatingPoints = (operatingPoints: operatingPointType[]) => {
        let tmp = [...operatingPoints];
        return tmp.splice(0, columnsCount).map((op, i) => {
            if (i > 0) {
                return {
                    FlowRateQ: {Value: op.FlowRateQ.Value, UnitId: operatingPoints[0].FlowRateQ.UnitId},
                    InletPressureP1: {Value: op.InletPressureP1.Value, UnitId: operatingPoints[0].InletPressureP1.UnitId},
                    OutletPressureP2: {Value: op.OutletPressureP2.Value, UnitId: operatingPoints[0].OutletPressureP2.UnitId},
                    TemperaturT1: {Value: op.TemperaturT1.Value, UnitId: operatingPoints[0].TemperaturT1.UnitId}
                }
            } else {
                return op;
            }
        });
    };

    const clearAll = () => {
        clearInputs();
        clearCalculation();
    }

    const clearInputs = () => {
        setIsMediumFluid(false);
        setIsMediumSteam(false);
        setGeneralSettings(defaultGeneralSettings);
        setOperatingPoints([
            defaultOperatingPoint,
            defaultOperatingPoint,
            defaultOperatingPoint,
            defaultOperatingPoint,
            defaultOperatingPoint,
            defaultOperatingPoint,
        ] as operatingPointType[]);
        setColumnsCount(1);
    };

    const clearCalculation = () => {
        setHasChanges(false);
        setIsCalculated(false);
        setAlerts([]);
        setCalculationAlerts([]);
        setResults([]);
        setProductList([]);
    };

    const calculate = async(search = false, e?:FormEvent<HTMLFormElement>) => {
        if (e) {
            e.preventDefault()
        }

        const target = e?.target as HTMLFormElement;
        if (!target.checkValidity()) {
            return;
        }

        setIsLoading(true);
        await axios.post(config?.proxyUrl + `/ValveCalc/api/` + config?.apiVersion + `/Calculation/` + (search ? 'calcAndSearch' : 'calc'), {
            UebergreifendeNutzereingaben: {
                ...generalSettings,
                InletLimit: generalSettings.useVelocityLimit ? generalSettings.InletVelocityLimit : generalSettings.InletPipeLimit,
                OutletLimit: generalSettings.useVelocityLimit ? generalSettings.OutletVelocityLimit : generalSettings.OutletPipeLimit,
                InletVelocityLimit: undefined,
                InletPipeLimit: undefined,
                OutletVelocityLimit: undefined,
                OutletPipeLimit: undefined,
            },
            Betriebspunkte: fixOperatingPoints(operatingPoints)
        }).then((res: IResponse) => {
            let tmpResults = search ? res.data.CalcResultReponse.ResultsProBetriebspunkt : res.data.ResultsProBetriebspunkt;
            const alerts = [];

            if (generalSettings.useVelocityLimit && res.data.CalcResultReponse.SuggestedPipeSizeIn.Value !== res.data.CalcResultReponse.SuggestedPipeSizeOut.Value) {
                alerts.push({
                    severity: 'warning',
                    children: t('Alert.WARNING.DIFFERENT_PIPE_SIZES', {SuggestedPipeSizeIn: res.data.CalcResultReponse.SuggestedPipeSizeIn, SuggestedPipeSizeOut: res.data.CalcResultReponse.SuggestedPipeSizeOut, })
                } as AlertProps);
            }

            const calculationAlerts = res.data.CalcResultReponse.HinweiseGlobal.filter(hinw => hinw.MessageText !== 'DIFFERENT_PIPE_SIZES').map((a) => { return {
                ...a,
                children: t('Alert.' + a.Severity + '.' + a.MessageText),
                severity: a.Severity?.toLowerCase(),
                MessageText: undefined,
                MessageType: undefined,
                Severity: undefined,
            } as AlertProps}) ?? [];

            let numberOfChangedFluidStates = 0;

            if (tmpResults) {
                tmpResults = tmpResults.map((result: ResultType, index: number) => {
                    if (results[index] && results[index].isMediumFluid !== result.isMediumFluid) {
                        numberOfChangedFluidStates++;
                    }

                    return {
                        ...result,
                        isMediumFluidTmp: results[index] ? results[index].isMediumFluid : undefined
                    }
                });

                if (tmpResults[0].isMediumFluid !== isMediumFluid) {
                    setIsMediumFluid(tmpResults[0].isMediumFluid);
                    setIsMediumSteam(tmpResults[0].isMediumSteam)
                    setOperatingPoints((prevState) => {
                        return prevState.map(op => {
                            return {
                                ...op,
                                FlowRateQ: {
                                    ...op.FlowRateQ,
                                    // @ts-ignore
                                    UnitId: tmpResults[0].isMediumSteam ? 'kg/h' : (tmpResults[0].isMediumFluid ? 'm3/h' : 'm3/h{norm}')
                                }
                            }
                        });
                    });
                    if (isCalculated) {
                        alerts.push({
                            severity: 'warning',
                            children: t('Alert.WARNING.FLUID_PHASE_CHANGED')
                        } as AlertProps);
                    }
                }

                setResults(tmpResults);
            }

            if (numberOfChangedFluidStates > 0) {
                alerts.push({
                    severity: 'warning',
                    children: numberOfChangedFluidStates > 1 ? t('Alert.WARNING.ChangedFluidStates', 'Alert.WARNING.ChangedFluidStates {{number}}', {
                        number: numberOfChangedFluidStates
                    }) :  t('Alert.WARNING.ChangedFluidState')
                } as AlertProps);
            }

            if (search) {
                setGeneralSettings((prevState) => {
                    return {
                        ...prevState,
                        // useVelocityLimit: false,
                        InletPipeLimit: res.data.CalcResultReponse.SuggestedPipeSizeIn ?? prevState?.InletPipeLimit,
                        OutletPipeLimit: res.data.CalcResultReponse.SuggestedPipeSizeOut ?? prevState?.OutletPipeLimit,
                    }
                });
                setProductList(res.data.ProduktVorschlaege);
            }

            setAlerts(alerts);
            setCalculationAlerts(calculationAlerts);
            setIsLoading(false);
            setIsCalculated(true);
            setHasChanges(false);
        }).catch((error) => {
            console.error(error);
            setCalculationAlerts([{
                severity: 'error',
                children: '500: ' + t('Alert.ERROR.' + error.code)
            }]);
            setAlerts([]);
            setResults([]);
            setProductList([]);
            setIsLoading(false);
        });
    }

    const openDialog = (title: string, contentText: string, callback: () => void) => {
        setDialog({
            open: true,
            title,
            contentText,
            callback
        })
    }

    const disabledCalcButton = () => {
        return !(generalSettings.FluidId && generalSettings.useVelocityLimit !== undefined);
    }

    const isAbsoluteViscosity = () => {
        if (!results[0].BerechneteEigenschaftenMediumFluid?.Viscosity?.UnitId || !supportedUnitIds?.Viscosity) return false;

        return supportedUnitIds?.Viscosity.find((supportedUnitId) => {
            return Object.keys(supportedUnitId).includes(results[0].BerechneteEigenschaftenMediumFluid?.Viscosity?.UnitId!);
        });
    }

    // @ts-ignore
    return <Container sx={{...sx, position: 'relative'}} maxWidth={false} key={'ValvePilot'}>
        {/*<Typography variant='h4'>{t('Global.TITLE')}</Typography>*/}
        {/*<Box  sx={{marginBottom: 2}}>
            <Typography variant='subtitle1'>{t('Global.SUBTITLE')}</Typography>
            {i18n.exists('Global.SUBTITLE_LINK_URL') && <Link href={t('Global.SUBTITLE_LINK_URL')}>{i18n.exists('Global.SUBTITLE_LINK_TEXT') ? t('Global.SUBTITLE_LINK_TEXT') : t('Global.SUBTITLE_LINK_URL')}</Link>}
        </Box>*/}
        <Box id='appLanguageWrapper' sx={{marginBottom: 1, display: 'flex', justifyContent: 'end' }}>
            {config?.languages && <NativeSelect id='appLanguageSelect' variant={'outlined'} size={'small'}
                          defaultValue={language ?? i18n.language}
                          onChange={(e) => setLanguage(e.target.value)}
                          // sx={{position: 'absolute', right: '1.5rem', top: 0, paddingRight: 1}}
                          sx={{display: 'none'}}
                          input={<OutlinedInput />}
                          IconComponent={({ className }) =>  <SvgIcon component={Translate} inheritViewBox className={className} sx={{padding: '3px'}}/>}
            >
                {config?.languages?.map((l) => {
                    return <option key={Object.keys(l).toString()} value={Object.keys(l)}>{Object.values(l)}</option>
                })}
            </NativeSelect>}
        </Box>

        <form autoComplete="off" onSubmit={(e) => calculate(true, e)} ref={refForm} action={'#'}>
            <ValvePilotSizingInput generalSettings={generalSettings}
                                   operatingPoints={operatingPoints}
                                   columnsCount={columnsCount}

                                   fluids={fluids ?? []}
                                   supportedUnitIds={supportedUnitIds}

                                   isMediumFluid={isMediumFluid}
                                   isMediumSteam={isMediumSteam}
                                   isCalculated={isCalculated}

                                   setGeneralSettings={setGeneralSettings}
                                   setOperatingPoints={setOperatingPoints}
                                   setColumnsCount={setColumnsCount}

                                   setHasChanges={setHasChanges}
                                   setIsCalculated={setIsCalculated}
                                   setIsLoading={setIsLoading}
                                   setIsMediumFluid={setIsMediumFluid}
                                   setIsMediumSteam={setIsMediumSteam}

                                   clearCalculation={clearCalculation}
            />

            {alerts.map((alert:AlertProps, i) => <Alert key={i} {...alert} sx={{marginBottom: 2}}/>)}

            {results.length > 0 && <ValvePilotFluidProperties operatingPoints={operatingPoints}
                                                              results={results}
                                                              fluidId={generalSettings.FluidId}
                                                              supportedUnitIds={supportedUnitIds}
                                                              isMediumFluid={isMediumFluid}
                                                              isMediumSteam={isMediumSteam}
                                                              isAbsoluteViscosity={isAbsoluteViscosity()}
                                                              setIsLoading={setIsLoading}
                                                              setResults={setResults}
            />}

            <ButtonGroup>
                <ValvePilotCsvDownloadButton generalSettings={generalSettings} operatingPoints={operatingPoints} showIconButtons={showIconButtons} columnsCount={columnsCount} disabledCalcButton={disabledCalcButton()}/>

                <ValvePilotCsvUploadButton setGeneralSettings={setGeneralSettings}
                                           setOperatingPoints={setOperatingPoints}
                                           setCalculationAlerts={setCalculationAlerts}
                                           setIsMediumSteam={setIsMediumSteam}
                                           setIsMediumFluid={setIsMediumFluid}
                                           setColumnsCount={setColumnsCount}
                                           clearAll={clearAll}
                                           openDialog={openDialog}
                                           showIconButtons={showIconButtons}
                                           fluids={fluids}
                />

                <Button variant="contained" onClick={() => openDialog(t('Dialog.CLEAR.TITLE'), t('Dialog.CLEAR.CONTENT_TEXT'), clearAll)} startIcon={showIconButtons ? <SvgIcon component={Clear} inheritViewBox /> : undefined}>{t('Buttons.CLEAR_INPUT')}</Button>
                <Button type="submit" variant="contained" startIcon={showIconButtons ? <SvgIcon component={Calculate} inheritViewBox /> : undefined}>{t('Buttons.CALCULATE')}</Button>

                {dialog && <Dialog
                    open={dialog.open}
                    onClose={() => setDialog(undefined)}
                    fullWidth
                    maxWidth={'sm'}
                >
                    <DialogTitle>{dialog.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {dialog.contentText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" startIcon={showIconButtons ? <SvgIcon component={Cancel} inheritViewBox /> : undefined} onClick={() => {setDialog(undefined); }}>{t('Buttons.CANCEL')}</Button>
                        <Button variant="contained" startIcon={showIconButtons ? <SvgIcon component={Check} inheritViewBox /> : undefined} onClick={() => {dialog.callback(); setDialog(undefined);}}>{t('Buttons.OK')}</Button>
                    </DialogActions>
                </Dialog>}
            </ButtonGroup>

            {calculationAlerts.filter(h => h.severity === 'error').length === 0 && results.length > 0 && <ValvePilotOurSolutions results={results} columnsCount={columnsCount} maxNoisePressure={85} minNoisePressure={70}/>}

            {calculationAlerts.map((alert:AlertProps, i) => <Alert key={i} {...alert} sx={{marginBottom: 2}}/>)}

            {calculationAlerts.filter(h => h.severity === 'error').length === 0 && isCalculated && productList.length > 0 && <ValvePilotProductList productList={productList}/>}
            {calculationAlerts.filter(h => h.severity === 'error').length === 0 && isCalculated && productList.length <= 0 && <Alert severity={'info'}>{t('Devices.NoProductsFound')}</Alert>}
        </form>

        <Backdrop open={isLoading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </Container>
};
export default ValvePilot;

import React, {FC, ReactNode} from 'react';
import {Box, styled, SxProps, Tooltip} from '@mui/material';

export interface ValvePilotTextBoxProps {
    children?: ReactNode;
    sx?: SxProps;
    tooltip?: React.ReactNode;
    textAlign?: CanvasTextAlign;
}

const TextBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',

    fontFamily:  theme.typography.fontFamily,
    backgroundColor: theme.palette.grey[300],
    padding: '0 0.5rem',
    fontWeight: 'unset',
    height: '40px',
    lineHeight: '1rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    justifyContent: 'space-between'
}));

const ValvePilotTextBox: FC<ValvePilotTextBoxProps> = ({children, sx, tooltip, textAlign}) => {
    return  <Tooltip title={tooltip ?? ''} arrow>
        <TextBox sx={{...sx, placeContent: textAlign, cursor: tooltip ? 'pointer' : undefined}}>
            {children}
        </TextBox>
    </Tooltip>
};

export default ValvePilotTextBox;

import React, {FC, ReactNode} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, SvgIcon, SxProps, Typography} from '@mui/material';
import {ReactComponent as ArrowDown} from '../../assets/icons/arrowDown.svg';
import {ReactComponent as Tune} from '../../assets/icons/tune.svg';

interface ValvePilotProps {
    sx?: SxProps;
    title: string;
    children?: ReactNode;
    defaultExpanded?: boolean;
}
const ValvePilotAccordion: FC<ValvePilotProps> = (props) => {
    const {sx, children, title, defaultExpanded} = props;

    return <Accordion defaultExpanded={defaultExpanded} sx={sx}>
        <AccordionSummary expandIcon={<SvgIcon component={ArrowDown} inheritViewBox/>}>
            <SvgIcon component={Tune} inheritViewBox sx={{marginRight: 1}}/>
            <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {children}
        </AccordionDetails>
    </Accordion>
};

export default ValvePilotAccordion;

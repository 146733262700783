import React, {
    FC,
    useState
} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Grid,
    List,
    ListItem,
    Tooltip,
    useTheme
} from '@mui/material';
import {
    Error,
    Info,
    Warning
} from '@mui/icons-material';
import ValvePilotAccordion from './ValvePilotAccordion';
import ValvePilotTableRow from './ValvePilotTableRow';
import ValvePilotTextBox from './ValvePilotTextBox';
import {ResultType} from './ValvePilot';

interface ValvePilotOurSolutionsProps {
    results: ResultType[];
    maxNoisePressure?: number;
    minNoisePressure?: number;
    columnsCount?: number;
}

const ValvePilotOurSolutions: FC<ValvePilotOurSolutionsProps> = (props) => {
    const {
        results,
        columnsCount,
        maxNoisePressure,
        minNoisePressure
    } = props;
    const {
        t,
        i18n
    } = useTranslation();
    const theme = useTheme();

    const [openTooltip, setOpenTooltip] = useState<any>();

    const formatNumberOrCnc = (value: number | null | undefined, fractionDigits: number) => {
        if(value === undefined) {
            return;
        }
        return value !== null ? Number(value).toLocaleString(i18n.language, {maximumFractionDigits: fractionDigits}) :
            <Tooltip title={t('Alert.INFO.CNC')} placement={'top'} arrow><Error color='error' sx={{cursor: 'pointer'}}/></Tooltip>
    }

    const getFractionDigits = (value: number | undefined) => {
        if(!value) return 0;
        if(value < 0.1) return 4;
        if(value < 1) return 3;
        if(value < 10) return 2;
        if(value < 100) return 1;
        // if(value >= 100) return 0;
        return 0;
    }

    return <ValvePilotAccordion title={t('Global.TITLE_OUR_SOLUTIONS')} defaultExpanded>
        <ValvePilotTableRow title={t('Solutions.CALCULATIONS')} info={t('Solutions.Info.CALCULATIONS', '')} sx={{fontWeight: 'bold'}}>
            <Grid item xs={1}><ValvePilotTextBox>{t('Solutions.UNITS')}</ValvePilotTextBox></Grid>
            <Grid item xs={results?.length ?? 6}><ValvePilotTextBox>{t('Solutions.SOLUTIONS')}</ValvePilotTextBox></Grid>
        </ValvePilotTableRow>

        <ValvePilotTableRow title={t('Solutions.KV_VALUE')} info={t('Solutions.Info.KV_VALUE', '')}>
            <Grid item xs={1}><ValvePilotTextBox>{t('Unit.FluidVolumeFlow.m3/h')}</ValvePilotTextBox></Grid>
            {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox
                textAlign={'end'}>{formatNumberOrCnc(r.Kv.Value, getFractionDigits(r.Kv.Value))}</ValvePilotTextBox></Grid>)}
        </ValvePilotTableRow>
        <ValvePilotTableRow title={t('Solutions.KVS_VALUE')} info={t('Solutions.Info.KVS_VALUE', '')} sx={{fontWeight: 'bold'}}>
            <Grid item xs={1}><ValvePilotTextBox>{t('Unit.FluidVolumeFlow.m3/h')}</ValvePilotTextBox></Grid>
            {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox
                textAlign={'end'}>{formatNumberOrCnc(r.Kvs.Value, getFractionDigits(r.Kvs.Value))}</ValvePilotTextBox></Grid>)}
        </ValvePilotTableRow>
        <ValvePilotTableRow title={t('Solutions.CVS_VALUE')} info={t('Solutions.Info.CVS_VALUE', '')}>
            <Grid item xs={1}><ValvePilotTextBox>{t('Unit.FluidVolumeFlow.[gal_us]/min')}</ValvePilotTextBox></Grid>
            {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox
                textAlign={'end'}>{formatNumberOrCnc(r.Cvs.Value, getFractionDigits(r.Cvs.Value))}</ValvePilotTextBox></Grid>)}
        </ValvePilotTableRow>
        <ValvePilotTableRow title={t('Solutions.PIPE_UP_DOWNSTREAM')} info={t('Solutions.Info.PIPE_UP_DOWNSTREAM', '')}>
            <Grid item xs={1}><ValvePilotTextBox>{t('Unit.Velocity.m/s')}</ValvePilotTextBox></Grid>
            {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox
                textAlign={'end'}>{formatNumberOrCnc(r.PipeUpstreamVelocity.Value, 1)} / {formatNumberOrCnc(r.PipeDownstreamVelocity.Value, 1)}</ValvePilotTextBox></Grid>)}
        </ValvePilotTableRow>
        <ValvePilotTableRow title={t('Solutions.VALVE_INLET_OUTLET')} info={t('Solutions.Info.VALVE_INLET_OUTLET', '')}>
            <Grid item xs={1}><ValvePilotTextBox>{t('Unit.Velocity.m/s')}</ValvePilotTextBox></Grid>
            {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox
                textAlign={'end'}>{formatNumberOrCnc(r.ValveInletVelocity.Value, 1)} / {formatNumberOrCnc(r.ValveOutletVelocity.Value, 1)}</ValvePilotTextBox></Grid>)}
        </ValvePilotTableRow>
        <ValvePilotTableRow title={t('Solutions.NOISE_PRESSURE')} info={t('Solutions.Info.NOISE_PRESSURE', '')}>
            <Grid item xs={1}><ValvePilotTextBox>{t('Unit.dB{A}')}</ValvePilotTextBox></Grid>
            {results.map((r, i: number) => <Grid key={i} item xs={1}>
                <ValvePilotTextBox textAlign={'end'} sx={maxNoisePressure && Number(r.PredictedNoiseLevel.Value) > maxNoisePressure ? {
                    color: theme.palette.primary.main,
                    fontWeight: 'bold'
                } : undefined}>
                    {minNoisePressure && Number(r.PredictedNoiseLevel.Value) > minNoisePressure ? formatNumberOrCnc(r.PredictedNoiseLevel.Value, 1) : '< ' + minNoisePressure}
                </ValvePilotTextBox>
            </Grid>)}
        </ValvePilotTableRow>
        <ValvePilotTableRow title={t('Solutions.FLANGE_DIAMETER')} info={t('Solutions.Info.FLANGE_DIAMETER', '')}>
            <Grid item xs={1}><ValvePilotTextBox>{t('Unit.PipeDimensions.mm{pipe}')}</ValvePilotTextBox></Grid>
            {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox
                textAlign={'end'}>{formatNumberOrCnc(r.FlangeDiameter.Value, 0)}</ValvePilotTextBox></Grid>)}
        </ValvePilotTableRow>
        <ValvePilotTableRow title={t('Solutions.REDUCTION_RATIO_P1_P2')} info={t('Solutions.Info.REDUCTION_RATIO_P1_P2', '')}>
            <Grid item xs={1}><ValvePilotTextBox></ValvePilotTextBox></Grid>
            {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox textAlign={'end'}>{formatNumberOrCnc(r.ReductionRatio, 1)}</ValvePilotTextBox></Grid>)}
        </ValvePilotTableRow>
        {results.filter((r) => r.Hinweise?.length > 0).length > 0 && <ValvePilotTableRow title={t('Information.Label')}>
          <Grid item xs={1}><ValvePilotTextBox></ValvePilotTextBox></Grid>
            {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox textAlign={'center'}>
                {r.Hinweise.filter(h => h.Severity === 'INFO').length > 0 && <Tooltip open={openTooltip === 'info_' + i}
                  title={<List dense sx={{padding: 0.5}}>{r.Hinweise.filter(h => h.Severity === 'INFO').map((h, i) => <ListItem
                      key={i}>{t('Alert.INFO.' + h.MessageText)}</ListItem>)}</List>} placement={'top'} arrow>
                  <Info color='info' sx={{cursor: 'pointer'}} onClick={() => openTooltip === 'info_' + i ? setOpenTooltip(undefined) : setOpenTooltip('info_' + i)} onMouseOver={() => setOpenTooltip('info_' + i)} onMouseLeave={() => setOpenTooltip(undefined)}/>
                </Tooltip>}
                {r.Hinweise.filter(h => h.Severity === 'WARNING').length > 0 && <Tooltip open={openTooltip === 'warning_' + i}
                  title={<List dense sx={{padding: 0.5}}>{r.Hinweise.filter(h => h.Severity === 'WARNING').map((h, i) => <ListItem
                      key={i}>{t('Alert.WARNING.' + h.MessageText)}</ListItem>)}</List>} placement={'top'} arrow>
                  <Warning color='warning' sx={{cursor: 'pointer'}} onClick={() => openTooltip === 'warning_' + i ? setOpenTooltip(undefined) : setOpenTooltip('warning_' + i)} onMouseOver={() => setOpenTooltip('warning_' + i)} onMouseLeave={() => setOpenTooltip(undefined)}/>
                </Tooltip>}
                {r.Hinweise.filter(h => h.Severity === 'ERROR').length > 0 && <Tooltip open={openTooltip === 'error_' + i}
                  title={<List dense sx={{padding: 0.5}}>{r.Hinweise.filter(h => h.Severity === 'ERROR').map((h, i) => <ListItem
                      key={i}>{t('Alert.ERROR.' + h.MessageText)}</ListItem>)}</List>} placement={'top'} arrow>
                  <Error color='error' sx={{cursor: 'pointer'}} onClick={() => openTooltip === 'error_' + i ? setOpenTooltip(undefined) : setOpenTooltip('error_' + i)} onMouseOver={() => setOpenTooltip('error_' + i)} onMouseLeave={() => setOpenTooltip(undefined)}/>
                </Tooltip>}
            </ValvePilotTextBox></Grid>)}
        </ValvePilotTableRow>}
    </ValvePilotAccordion>
};
export default ValvePilotOurSolutions;
